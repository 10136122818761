#root {
    font-size: .875em !important;
    background-color: #fff;
}

@font-face {
    font-family: Nunito;
    src: url('static/fonts/NunitoSans-Regular.ttf');
}

@font-face {
    font-family: Bebas;
    src: url('static/fonts/BebasNeue-Regular.ttf');
}

@font-face {
    font-family: Pinguin;
    src: url('static/fonts/Penguin-Regular.ttf');
}

@font-face {
    font-family: Brusher;
    src: url('static/fonts/Brusher.ttf');
}

@font-face {
    font-family: Robo;
    src: url('static/fonts/BebasNeue-Regular.ttf');
}

a {
    color: #131415 !important;
    font-weight: bold;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: bold !important;
    line-height: 1.5 !important;
}

.h1, h1 {
    font-size: 1.5rem !important;
}

.h2, h2 {
    font-size: 1.3rem !important;
}

.h3, h3 {
    font-size: 1rem !important;
}

.h4, h4 {
    font-size: 0.9rem !important;
}

.h-100 {
    min-height: 50vh !important;
    height: auto;
}

.text-primary {
    color: #ffc107 !important;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.loader-sm {
    height: 10vh;
}

.loader-xsm {
    height: 3vh;
    justify-content: left;
}

span.nav-text {
    font-size: 0.9rem;
}

body {
    font-family: Nunito, sans-serif !important;
    background-color: #fafafa !important;
}

.row {
    margin: 0 !important;
}

.brand {
    font-family: Brusher, sans-serif;
    font-size: 2rem !important;
    color: #131415 !important
}

.brand-v {
    color: #ffc107 !important;
}

.nav-tabs .nav-link {
    background: #ffc107a8 !important;
    color: #000000 !important;
}

.link-auth {
    color: #000000 !important;
    margin-left: 1rem !important;
    padding-bottom: 0.4rem;
}

.link-no-auth {
    color: #000000 !important;
    margin-left: 1rem !important;
    padding: 0.5rem 1rem 0.6rem !important
}

.link-no-auth:hover {
    color: #000000 !important;
    font-weight: bold;
}

.sign-icon{
    font-size: 15px;
}

.link-register {
    border-radius: 1rem;
    background: #ffc107a8;
}

.nav-link-log-out {
    padding: 0 !important;
}

.nav-tabs {
    border-bottom: none !important;
    margin: 0 1rem;
}

.table td, .table th {
    border: none !important;
    vertical-align: middle !important;
    line-height: 1.5rem;
}

.tab-content {
    min-height: 15rem;
}

nav.navbar.navbar-expand-lg.navbar-light {
    border-bottom: 2px solid !important;
}

.admin-nav {
    background: #e3e3e3 !important;
}

.no-style {
    list-style: none;
}

button#dropdown-basic-button {
    background: transparent !important;
    border: none;
}

.dropdown-element {
    display: inline-block;
}

.dropdown-toggle::after {
    vertical-align: 0.055em !important;
}

.nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    color: #000000 !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #000000 !important;
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.page-link {
    font-size: 0.6rem !important;
}

.active > .page-link, .page-link.active {
    z-index: 3;
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
}

.jumbotron {
    height: 100vh;
    margin-bottom: 0;
    background-color: #fff !important;
}

.jumbotron-right {
    background-image: url('static/img/prosurfingtools_background_sm.png');
    background-position: center;
    background-size: 100vh;
    background-repeat: no-repeat;
}

img, svg {
    margin-bottom: 1px;
}

.container-full-bg {
    background: transparent;;
}

.bg-homepage {
    background-image: url('static/img/uuundulate_1.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 80%;
}

.smartwatch-area {
    background: rgba(255, 255, 255, 0.8);
}

.newsletter {
    margin-top: 1rem;
}

.container-primary-bg {
    background-color: #ffc107;
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #ffc107 25px), repeating-linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
}

.product-description-area {
    background: #f6f6f6;
    color: #131415;
    font-weight: bolder;
}

small.text-muted-requirement {
    font-size: 0.7rem;
}

.p-list {
    margin-bottom: 0.3rem;
}

.jumbotron-right, .jumbotron-left {
    min-height: 65vh;
}

.product-icon {
    font-size: 7vh;
}

.product-text {
    width: 80%;
}

.product-description-text {
    font-size: 0.8rem;
}

.form-control {
    font-size: .875em !important;
}

.form-control:focus {
    border-color: #ffc107 !important;
    box-shadow: none !important;
}

.form-label {
    font-size: .875em !important;
    font-weight: bold !important;
    margin-bottom: 0 !important;
}

.form-element {
    margin-bottom: 1rem !important;
}

.aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.jumbotron-download {
    margin-top: 5vh !important;
}

h1.jumbotron-title {
    font-size: 4rem !important;
    font-weight: bold;
}

.brand-font {
    font-family: Brusher, sans-serif;
}

.wave {
    text-decoration: overline wavy !important;
}

.google-play-badge > img {
    width: 25vh !important;
    margin-top: 1rem;
}

.bg-black {
    background-color: #131415 !important;
}

.bg-transparent {
    background-color: transparent !important;
}


.btn {
    line-height: unset !important;
}

.btn-home{
    font-weight: bolder !important;
}

.btn-primary {
    color: #000 !important;
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.btn-warning {
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.btn-danger {
    color: #131415 !important;
    background-color: #e41a4b !important;
    border-color: #e41a4b !important;
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #000 !important;
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.btn-light {
    background-color: transparent !important;
    border: transparent !important;
}


.me-2.btn-group {
    margin: 0.2rem;
}

a {
    color: #000000 !important;
    font-weight: normal !important;
}

a:hover {
    color: #000000 !important;
    font-weight: bold !important;
}

.a-footer {
    color: #ffc107 !important;
}

.a-footer:hover {
    color: #ffc107 !important;
}

footer a {
    color: #ffc107 !important;
}

footer a:hover {
    color: #ffc107 !important;
    font-weight: bold;
}

footer li {
    margin-bottom: 0 !important;
}

#content-dashboard {
    background: #fff;
}

.dashboard-number {
    font-size: 4rem;
}

.dashboard-card {
    align-items: center;
}

.dashboard-surfers {
    background: #e4f28a !important;
}

.dashboard-bots {
    background: #f2f2f2 !important;
}

.dashboard-trainings {
    background: #ffc4c4 !important;
}

.dashboard-products {
    background: #b1e3ff !important;
}

.dashboard-heats {
    background: #ffd9b1 !important;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 1rem 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -7rem;
    padding: 0;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
}

.sidebar-sticky .nav-item a {
    padding: 0.5rem;
}

.hr-sm {
    width: 60%;
    margin: 0.5rem 0;
}

.version {
    background: #ffc107;
    height: 0.9rem;
    font-weight: 100;
    font-size: 11px;
    width: fit-content;
}

.version-text {
    color: black !important;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.container-fluid {
    min-height: 0;
}

a.navbar-brand-pro.brand.navbar-brand {
    padding: 0;
    z-index: 1;
    font-weight: bold !important;
}

.navbar-brand-pro {
    flex: auto;
    text-align: left;
}

.brand-logo {
    border-radius: 50%;
    margin: 0.2rem 0.7rem 0 0;
}

.nav-icons {
    font-size: 1.5rem;
}

img.profile-img.rounded-circle {
    height: 5rem;
    margin: 1rem;
}

img.profile-img-nav.rounded-circle {
    height: 2rem;
}

.disabled {
    color: grey !important;
}

.card, .accordion {
    margin-bottom: 1rem;
    border: none !important;
    background: #ffffff;
}

.news-card-link {
    text-decoration: none !important;
}

.news-card-link:hover {
    font-weight: unset !important;
}

.news-card {
    transition: background-color 0.5s ease;
}

.news-card:hover {
    background-color: #ffc107;
}

.news-card-article {
    background: transparent !important;
}

.accordion-button {
    background: #ffffff !important;
    color: #000 !important;
}

.accordion-body {
    background: #ffffff !important;
    color: #000 !important;
    padding: 1rem 0 0 !important;
}

.accordion-item {
    border: none !important;
}

.stats-heat-card-header {
    padding: 0;
    background: #ffc107 !important;
}

@media print {
    .chart-stat {
        width: 50% !important;
    }

    .chart-table {
        width: 50% !important;
    }
}

.pl-1 {
    padding-left: 1rem !important;
}

.countdown-timer {
    font-size: 3.5rem;
    font-weight: bold;
}

.vertical-center {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.accordion-header {
    text-transform: capitalize !important;
}

.accordion-header:hover {
    cursor: pointer;
    background-color: #dedede !important;
}

.accordion-header.card-header {
    color: #000;
}

.card-header, .accordion-button {
    text-transform: uppercase;
    font-size: 14px !important;
    font-weight: bold;
    background: transparent;
    padding-left: 0 !important;
    border-bottom: 1px solid #dfdedb !important;
}

.card-header > h2 {
    padding: 0 1rem;
    font-size: 0.9rem !important;
}

.card-body, .list-group-item {
    padding: 1rem 0 0 !important;
}

.card-footer {
    background: #ffffff !important;
    padding: 1rem !important;
}

.card-surfer-body {
    font-family: Nunito, sans-serif;
    font-weight: bold;
    font-size: 1rem;
}

.sidebar-user {
    text-align: center;
    padding-bottom: 1.5rem;
}

.text-muted {
    color: #b5bcc3 !important;
}

.footer-copyright {
    font-size: 0.7rem;
}

.input-range {
    margin-bottom: 2rem;
    margin-top: 3rem;
}

.input-range__label {
    font-family: Nunito, sans-serif !important;
    background-color: #fafafa !important;
}

.input-range__label--value {
    position: absolute !important;
    top: -2.8rem !important;
    background: transparent !important;
    color: #131415 !important;
}

.input-range__track--active {
    background: #ffc107 !important;
}

.input-range__slider {
    background: #ffc107 !important;
    border-color: #ffc107 !important;
}

.input-range__label--max .input-range__label-container, .input-range__label--min {
    display: none;
}

.divider {
    text-align: center;
    flex: .2 0 auto;
    margin: 0;
    height: 12px;
}

.divider-wrapper {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    border: none;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: .5rem 0 1rem;
    align-items: center;
    justify-content: center;
    vertical-align: baseline;
}

.divider-wrapper:before, .divider-wrapper:after {
    content: "";
    border-bottom: 1px solid #c2c8d0;
    flex: 1 0 auto;
    height: .5em;
    margin: 0;
}

.social-logo {
    width: 20px;
    height: 20px;
    display: inline-block;
}

.social-logo-wrapper {
    position: absolute;
    left: 26px;
    top: 50%;
    transform: translate(-50%) translateY(-50%);
}

.social-text {
    text-align: center;
    position: relative;
}

.social-btn {
    position: relative;
    border: 1px solid #c2c8d0;
    border-radius: 6px;
    font-size: 16px;
    align-items: center;
    background-color: #fff;
    height: 52px;
    cursor: pointer;
    color: #2d333a;
    margin-bottom: 8px;
    display: flex;
    outline: 0;
    padding: 0 8px 0 52px;
}

.countdown {
    font-size: 4rem !important;
    font-weight: bold !important;
}

.winner {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #ffc107;
    margin: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.btn {
    font-size: .875em !important;
}

.button-actions-surfer {
    width: 100%;
}

.product-price-quantity {
    font-size: 2.7rem;
    font-weight: bold;
}

/* Rich Editor */

.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
}

.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #5890ff;
}

/* End Rich Editor */

.rbt-token {
    background-color: #ffc107 !important;
    color: #131415 !important;
}

.flex-column {
    flex-direction: unset !important;
}

.flex-column-footer {
    flex-direction: column !important;
}

/* Responsive breakpoints */
@media (max-width: 576px) {
    .sidebar-sticky .nav-item a {
        text-align: left;
        padding: 0.5rem 0;
    }
}

@media (max-width: 992px) {
    .navbar-collapse {
        display: none !important;
    }

    .flex-column {
        flex-direction: column !important;
    }

    .link-no-auth, .link-auth {
        padding: 0.5rem 0 !important;
        text-align: center;
    }

    .p-5 {
        padding: 0.2rem !important;
    }

    .p-3 {
        padding: 0.5rem 0 !important;
    }
}
